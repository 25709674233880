.pic {
  height: 17.75%;
  width: 96.75%;
  pointer-events: none;
  border-radius: 25px;
  margin: 10px;
  margin-right: 0;
}

.wou-logo {
  max-height: 7vmax;
  border-radius: 25px;
}

.project-logo {
  height: 3.5vmax;
}

body {
  min-height: 150%;
  color: #f8f9fb;
}

h1 {
  font-size: 790.5%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

h2 {
  font-size: 416.6%;
  font-style: italic;
  color: #D3D3D3;
}

h2:hover {
  color: #f8f9fb;
}

ul {
  list-style-type: none;
  padding-left: 0;
}
.title {
  margin-bottom: 12px;
}

.title span {
  position: relative;
  padding-bottom: 7px;
}

.title span::after{
  position: absolute; 
  left: 0; 
  bottom: 0; 
  width: 100%; 
  height: 1px; 
  border-bottom: 1px solid #f8f9fb; 
  content: ""
}

.tilte p {
  font-size: 100%;
  padding-bottom: 3px;
}

.social-tag {
  margin: 16.1px;
  font-weight: bold;
}

.social-icon {
  margin: 3px;
}

.item {
  background-color: rgb(0,0,0,.6);
  border-radius: 25px;
  margin: 10px;
  margin-right: 0;
  margin-left: 5px;
}

.item-name {
  padding-right: 35px;
  padding-left: 25px;
  padding-right: 25px;
  width: 725px;
}

.item-tabs {
  padding: 15px;
  width: 725px;
}

.content {
  padding-left: 5.5px;
  padding-left: 5.5px;
}

a {
  color: #03c04a;
}

a:hover {
  color: #ffa573;
  text-decoration: none;
}